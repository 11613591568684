import React, { useEffect, useRef, useState } from "react"
import moment from "moment"
import _ from "lodash"
import html2pdf from "html2pdf.js"
import { Helmet } from "react-helmet"
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs"
import { useForm } from "react-hook-form"
import { Form, Button, Modal } from "react-bootstrap"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { useDispatch } from "react-redux"
import Loader from "../Loader"
import * as SurveyAPI from "../../api/SurveyAPI"
import CommonTextInput, { CommonTextInputPrev } from "../Input/CommonTextInput"
import CheckBox from "../Input/CheckBox"
import { FormFieldDatePickers } from "../Input/FormField"
import { DRAGGABLE_FORM_CONSTANTS } from "../../common/constants"
import Status from "../Status"
import { showUpdatedToasterMessage } from "../../store/slices/toaster/toasterslice"
import ConfirmModal from "./ConfirmModal"
import { Denied_Msg } from "../../assets/data/menu"
import { checkPermission } from "../../api/AuthApi"
import closeIcon from "../../assets/images/close.svg"
import noteIcon from "../../assets/images/survey-note.svg"
const REJECT_SURVEY = {
  show: false,
  isLoading: false,
  title: "Not approve",
  description: "Do you really want to not approve this survey?",
  action: ""
}
const PreviewSurvey = ({ isActive, permission }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { id, status } = useParams()
  const headerRef = useRef(false)
  let c = 0
  const [modal, setModal] = useState(REJECT_SURVEY)
  const [loading, setLoading] = useState(false)
  const [loadingExport, setLoadingExport] = useState(false)
  const [progress, setProgress] = useState(0)
  const [surveyInvoice, setSurveyInvoice] = useState([])
  const [notes, setNotes] = useState(false)
  const [note, setNote] = useState("")
  const [surveyDetail, setSurveyDetail] = useState({
    client_name: "",
    project_name: "",
    round_name: "",
    site_name: "",
    site_location: "",
    fieldrep_name: "",
    schedule_date: "",
    status: "",
    service_code: "",
    template_column: ""
  })

  const [surveyData, setSurveyData] = useState([])
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    control,
    setError,
    formState: { errors }
  } = useForm({
    defaultValues: {}
  })

  const markAsApprove = async () => {
    try {
      const response = await SurveyAPI.approved(id)
      if (response.status == true) {
        dispatch(
          showUpdatedToasterMessage({
            message: "Survey approved successfully",
            type: "success"
          })
        )
        setSurveyDetail({ ...surveyDetail, status: response.data.status })
        navigate("/surveys")
      }
    } catch (e) {
    } finally {
    }
  }
  const markAsNotApprove = async () => {
    try {
      const response = await SurveyAPI.notApproved(id)
      if (response.status == true) {
        dispatch(
          showUpdatedToasterMessage({
            message: "Survey mark as unapproved successfully",
            type: "success"
          })
        )
        navigate("/surveys")
        // navigate(`/surveys/preview/${id}/${status}`)
      }
    } catch (e) {
    } finally {
    }
  }
  const deleteNote = async (id) => {
    try {
      const response = await SurveyAPI.deleteNote(id)
      if (response.status == true) {
        dispatch(
          showUpdatedToasterMessage({
            message: "Survey note deleted successfully",
            type: "success"
          })
        )
        window.location.reload()
        // navigate(`/surveys`);
      }
    } catch (e) {
    } finally {
    }
  }

  const fetchPreviewData = async () => {
    setLoading(true)
    try {
      const response = await SurveyAPI.previewData(id, status)
      if (response.status == true) {
        setSurveyInvoice(response.data)
        setSurveyDetail(response.data[response.data.length - 1])
        const formattedData = _.map(response?.data, (item) => {
          if (item.ques_type === "date" && item.ans) {
            item.ans = moment(item.ans, "DD-MMM-YYYY").format("MM/DD/YYYY")
          }
          return item
        })
        setSurveyData(formattedData)

        const defaultValues = {}

        formattedData.forEach((item, index) => {
          const uniqueKey = item.ques_no != "name" ? item.ques_no : ""
          defaultValues[uniqueKey] = item.ans == "null" || null ? null : item.ans
        })

        Object.keys(defaultValues).forEach((key) => {
          setValue(key, defaultValues[key])
        })
      }
    } catch (error) {
      dispatch(
        showUpdatedToasterMessage({
          message: error.message,
          type: "danger"
        })
      )
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (surveyDetail?.survey_id) {
      if (surveyInvoice?.length > 0) {
        surveyInvoice.forEach((item) => {
          if (item.ques_type === "number") {
            setValue(item.ques_no, surveyDetail.survey_id)
          }
        })
      }
    }
  }, [surveyDetail, surveyInvoice])

  useEffect(() => {
    fetchPreviewData()
  }, [])

  const goBack = () => {
    navigate("/surveys")
  }

  const simulateProgress = (target, increment) => {
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        setProgress((prev) => {
          if (prev >= target) {
            clearInterval(interval)
            resolve()
            return target
          }
          return Math.min(prev + increment, target)
        })
      }, 50)
    })
  }

  const exportToPDF = async () => {
    setLoadingExport(true)
    setProgress(0)

    try {
      const permission = await checkPermission("import_survey")
      if (!permission) {
        dispatch(
          showUpdatedToasterMessage({
            message: Denied_Msg,
            type: "error"
          })
        )
        setLoadingExport(false)
        return
      }

      const response = await SurveyAPI.markAsExport(id)
      if (response.status === true) {
        const content = document.getElementById("previewPdf")
        if (content) {
          const clonedContent = content.cloneNode(true)
          const elementsToHide = clonedContent.querySelectorAll(".hide-in-pdf")
          const elementFormLogo = clonedContent.querySelectorAll(".remove-item")
          const userDataHeading = clonedContent.querySelectorAll(".user-data small")
          const HeaderRow = clonedContent.querySelectorAll(".text-input")
          const FileInputRow = clonedContent.querySelectorAll(".file-form")

          HeaderRow.forEach((element) => {
            if (element.tagName.toLowerCase() === "input") {
              const pTag = document.createElement("p")
              pTag.textContent = element.value || element.placeholder || ""

              pTag.className = element.className
              pTag.id = element.id
              pTag.style.cssText = element.style.cssText
              element.replaceWith(pTag)
            }
          })

          const textareaRow = clonedContent.querySelectorAll("textarea")
          textareaRow.forEach((element) => {
            if (element.tagName.toLowerCase() === "textarea") {
              const pTag = document.createElement("p")
              pTag.textContent = element.textContent || element.placeholder || ""
              pTag.className = element.className
              pTag.id = element.id
              pTag.style.cssText = element.style.cssText
              element.replaceWith(pTag)
            }
          })
          elementsToHide.forEach((element) => element.remove())
          elementFormLogo.forEach((element) => element.remove())

          const dateRow = clonedContent.querySelectorAll(".date-input")
          dateRow.forEach((element) => {
            element.style.paddingLeft = "40px"
          })
          //exportdata
          userDataHeading.forEach((element) => {
            element.style.minWidth = "150px"
          })

          FileInputRow.forEach((element) => {
            element.style.paddingTop = "5px"
            element.style.lineHeight = "40px"
            element.style.display = "flex"
          })

          const opt = {
            // margin: [10, 2],
            filename: "survey_" + id + ".pdf",
            image: { type: "jpeg", quality: 0.98 },
            enableLinks: true,
            html2canvas: {
              scale: 3,
              useCORS: true
            },
            pagebreak: { mode: ["avoid-all"] },
            jsPDF: {
              unit: "pt",
              format: "a4",
              orientation: "portrait"
            }
          }

          const worker = html2pdf().from(clonedContent).set(opt)

          // Simulate progress updates
          await worker.toContainer()
          await simulateProgress(33, 2)

          await worker.toCanvas()
          await simulateProgress(66, 2)

          await worker.toPdf()
          await simulateProgress(100, 2)

          await worker.save()
        } else {
          throw new Error("Content to export not found")
        }
      }
    } catch (error) {
      dispatch(
        showUpdatedToasterMessage({
          message: error.message || "An error occurred during PDF export",
          type: "error"
        })
      )
    } finally {
      setLoadingExport(false)
      setProgress(0) // Reset progress after completion
    }
  }

  const onSubmit = () => {}

  const onConfirmAction = () => {
    if (modal.action == "refuseSurvey") {
      markAsNotApprove()
    }
    if (modal.action == "approveSurvey") {
      markAsApprove()
    }
    if (modal.action == "deleteNote") {
      deleteNote(id)
    }
  }

  const handleNoteSave = async () => {
    if (!note.trim()) {
      dispatch(
        showUpdatedToasterMessage({
          message: "Please enter a survey note before submitting",
          type: "error"
        })
      )
      return
    }
    const payload = {
      notes: note
    }
    try {
      const response = await SurveyAPI.saveNote(id, payload)
      if (response.status == true) {
        dispatch(
          showUpdatedToasterMessage({
            message: "Survey note added successfully",
            type: "success"
          })
        )
        window.location.reload()
        // navigate("/surveys");
      } else if (response.code == 204) {
      }
    } catch (e) {
    } finally {
    }
  }

  // Close add survey note modal on esc key press
  useEffect(() => {
    const handleEscKey = (e) => {
      if (e.key === "Escape") {
        setNotes(false)
      }
    }

    document.addEventListener("keydown", handleEscKey)

    return () => {
      document.removeEventListener("keydown", handleEscKey)
    }
  }, [])

  return (
    <>
      {modal.show && (
        <ConfirmModal
          {...modal}
          onActionCallback={onConfirmAction}
          onHide={() => {
            setModal((preveState) => ({
              ...preveState,
              show: false,
              title: "Delete Assigned Surveys",
              description: "Are you sure you want to delete assigned survey?",
              actionBtnText: "Delete Instruction",
              action: "DeleteInstruction"
            }))
          }}
        />
      )}
      {loading ? (
        <Loader />
      ) : (
        <main className={`${isActive && "active"}`}>
          <section className="dashboard">
            <Helmet>
              <title>ARS | Survey Details</title>
            </Helmet>
            <Breadcrumbs
              links={[
                { label: "Home", path: "/" },
                { label: "Surveys", path: "/surveys" },
                { label: "Survey Details" }
              ]}
            />
            <div className="survey-questions" id="previewPdf">
              <div className="container-fluid p-0">
                <div className="row g-0 justify-content-center">
                  <div className="col-md-12">
                    <div className="card-shadow radius-5">
                      <div className="survey-header top-radius">
                        <div className="survey-title">
                          <h5>Survey Details</h5>
                        </div>
                        <Button
                          className={
                            surveyDetail?.filled_surveydata === "" ||
                            surveyDetail?.filled_surveydata == null
                              ? "btn round-add-btn font-14 d-flex align-items-center gap-2"
                              : "d-none"
                          }
                          onClick={() => setNotes(true)}
                        >
                          <svg
                            width="10"
                            height="10"
                            viewBox="0 0 10 10"
                            fill="#fff"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M9.26058 4.25626L5.68135 4.24032L5.66014 0.648496C5.62302 0.292501 5.32608 0.0162069 4.97081 0.000266762C4.57842 -0.01036 4.24966 0.297815 4.23905 0.691003L4.26026 4.24032L0.654516 4.2297C0.304546 4.25095 0.0235098 4.53256 0.00229957 4.88324C-0.0295158 5.27643 0.272731 5.6218 0.665121 5.65368L4.26557 5.68024L4.28678 9.2827C4.28678 9.67589 4.61023 10 5.00262 10C5.18821 9.98406 5.35789 9.89904 5.47985 9.76621C5.61242 9.644 5.69726 9.47398 5.70786 9.29332L5.68665 5.68024L9.23938 5.7015C9.43027 5.7015 9.60525 5.63242 9.73782 5.49959C9.89159 5.37738 9.98704 5.19673 9.99764 4.99482C10.0029 4.97888 9.99764 4.96294 9.99764 4.95231C9.98173 4.55381 9.65298 4.24564 9.26058 4.25626Z" />
                          </svg>
                          Add Note
                        </Button>
                      </div>
                      <div className="user-detail-card mb-20 single-column-detail bottom-radius">
                        <div className="user-detail-wrapper pdf-wrapper">
                          <div className="user-data">
                            <small>Client</small>
                            <span>:</span>
                            <h6>{surveyDetail?.client_name}</h6>
                          </div>
                          <div className="user-data">
                            <small>Round</small>
                            <span>:</span>
                            <h6>{surveyDetail?.round_name}</h6>
                          </div>
                          <div className="user-data">
                            <small>Site Location</small>
                            <span>:</span>
                            <h6>{surveyDetail?.site_location}</h6>
                          </div>
                          <div className="user-data">
                            <small>Schedule Date</small>
                            <span>:</span>
                            <h6>
                              {surveyDetail?.schedule_date
                                ? moment(surveyDetail?.schedule_date).format("MM/DD/YYYY")
                                : null}
                            </h6>
                          </div>
                          {surveyDetail.status == "approved" ? (
                            <div className="user-data">
                              <small>Approved Date</small>
                              <span>:</span>
                              <h6>
                                {surveyDetail?.approved_date
                                  ? moment(surveyDetail?.approved_date).format("MM/DD/YYYY")
                                  : null}
                              </h6>
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="user-data">
                            <small>Project</small>
                            <span>:</span>
                            <h6>{surveyDetail?.project_name}</h6>
                          </div>
                          <div className="user-data">
                            <small>Site</small>
                            <span>:</span>
                            <h6>{surveyDetail?.site_name}</h6>
                          </div>
                          <div className="user-data">
                            <small>FieldRep</small>
                            <span>:</span>
                            <h6>
                              {surveyDetail?.fieldrep_name ? surveyDetail?.fieldrep_name : " - "}
                            </h6>
                          </div>
                          <div className="user-data">
                            <small>FieldRep Code</small>
                            <span>:</span>
                            <h6>
                              {surveyDetail?.fieldrep_code ? surveyDetail?.fieldrep_code : " - "}
                            </h6>
                          </div>
                        </div>
                        {surveyDetail?.filled_surveydata && (
                          <div className="survey-note d-flex gap-3">
                            <div className="note-icon">
                              <img src={noteIcon} alt="note" width={40} height={40} />
                            </div>
                            <div className="survey-note-content">
                              <span className="survey-note-title">Survey Note</span>
                              <p className="survey-note-text">{surveyDetail?.filled_surveydata}</p>
                            </div>
                            <div
                              className="ms-auto cursor-pointer"
                              onClick={async () => {
                                setModal({
                                  ...modal,
                                  show: true,
                                  title: "Delete Note",
                                  description: "Do you really want to delete this survey note ?",
                                  action: "deleteNote"
                                })
                              }}
                            >
                              <svg
                                width="12"
                                height="16"
                                viewBox="0 0 12 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M10.8533 2H8.32017V1.49714C8.32017 0.674286 7.6978 0 6.93348 0H5.07727C4.31295 0 3.69058 0.674286 3.69058 1.49714V2H1.1574C0.513186 2 0 2.56 0 3.24571V4.25143C0 4.52571 0.207458 4.75429 0.458592 4.75429H11.5412C11.7924 4.75429 11.9998 4.52571 11.9998 4.25143V3.24571C12.0107 2.56 11.4976 2 10.8533 2ZM4.61868 1.49714C4.61868 1.22286 4.82614 0.994286 5.07727 0.994286H6.92256C7.17369 0.994286 7.38115 1.22286 7.38115 1.49714V2H4.61868V1.49714Z"
                                  fill="#999999"
                                />
                                <path
                                  d="M0.873548 5.74857C0.786197 5.74857 0.720684 5.82857 0.731603 5.90857L1.11376 14.5714C1.14652 15.3714 1.75798 16 2.50046 16H9.51037C10.2528 16 10.8643 15.3714 10.8971 14.5714L11.2792 5.90857C11.2792 5.81714 11.2137 5.74857 11.1373 5.74857H0.873548ZM7.8507 7.00571C7.8507 6.73143 8.05816 6.50286 8.30929 6.50286C8.56043 6.50286 8.76788 6.73143 8.76788 7.00571V13.5086C8.76788 13.7829 8.56043 14.0114 8.30929 14.0114C8.05816 14.0114 7.8507 13.7829 7.8507 13.5086V7.00571ZM5.54682 7.00571C5.54682 6.73143 5.75428 6.50286 6.00541 6.50286C6.25655 6.50286 6.464 6.73143 6.464 7.00571V13.5086C6.464 13.7829 6.25655 14.0114 6.00541 14.0114C5.75428 14.0114 5.54682 13.7829 5.54682 13.5086V7.00571ZM3.23202 7.00571C3.23202 6.73143 3.43948 6.50286 3.69061 6.50286C3.94175 6.50286 4.14921 6.73143 4.14921 7.00571V13.5086C4.14921 13.7829 3.94175 14.0114 3.69061 14.0114C3.43948 14.0114 3.23202 13.7829 3.23202 13.5086V7.00571Z"
                                  fill="#999999"
                                />
                              </svg>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="card-shadow radius-5">
                      <div className="survey-header top-radius">
                        <div className="survey-title">
                          <h5>Fill Survey</h5>
                          <span>
                            Service Key : <strong>{surveyDetail?.service_code || "  -"}</strong>
                          </span>
                        </div>
                        {/* <span
                        style={{maxWidth: "70%"}}
                          className={
                            surveyDetail?.filled_surveydata === "" ||
                            surveyDetail?.filled_surveydata == null
                              ? "d-none"
                              : ""
                          }
                        >
                          Note : {surveyDetail?.filled_surveydata}
                        </span> */}
                        <div className="survey-badge">
                          {/* <span className={
                              surveyDetail?.filled_surveydata === "" ||
                              surveyDetail?.filled_surveydata == null
                                ? "me-2"
                                : "d-none"
                            }
                            onClick={() => setNotes(true)} >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              width="24"
                              height="24"
                              stroke-width="2"
                            >
                              <path d="M5 3m0 2a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2z"></path>
                              <path d="M9 7l6 0"></path>
                              <path d="M9 11l6 0"></path>
                              <path d="M9 15l4 0"></path>
                            </svg>
                          </span> */}
                          {/* <span
                            className={
                              surveyDetail?.filled_surveydata === "" ||
                              surveyDetail?.filled_surveydata == null
                                ? "tab-badge completed me-4"
                                : "d-none"
                            }
                            onClick={() => setNotes(true)}
                          >
                            Add Note
                          </span> */}
                          {/* <span className={
                              surveyDetail?.filled_surveydata === "" ||
                              surveyDetail?.filled_surveydata == null
                                ? "d-none"
                                : "me-1"
                            }
                            onClick={async () => {
                              setModal({
                                ...modal,
                                show: true,
                                title: "Delete Note",
                                description:
                                  "Do you really want to delete this survey note ?",
                                action: "deleteNote",
                              });
                            }}>
                            <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-notes-off"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M7 3h10a2 2 0 0 1 2 2v10m0 4a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-14" /><path d="M11 7h4" /><path d="M9 11h2" /><path d="M9 15h4" /><path d="M3 3l18 18" /></svg>
                          </span> */}
                          {/* <span
                            className={
                              surveyDetail?.filled_surveydata === "" ||
                              surveyDetail?.filled_surveydata == null
                                ? "d-none"
                                : "me-1"
                            }
                            onClick={async () => {
                              setModal({
                                ...modal,
                                show: true,
                                title: "Delete Note",
                                description:
                                  "Do you really want to delete this survey note ?",
                                action: "deleteNote",
                              });
                            }}
                          >
                            Delete Note
                          </span> */}
                          <Status status={surveyDetail?.status} />
                        </div>
                      </div>
                      <section className="survey-questions bg-white bottom-radius">
                        <div className="container-fluid p-0">
                          <Form
                            onSubmit={handleSubmit(onSubmit)}
                            autoComplete="off"
                            className="card-form survey-preview-form"
                          >
                            <div className="row g-0 justify-content-center">
                              <div
                                className={`col-md-12 ${
                                  surveyDetail?.template_column == 2
                                    ? "double-column-layout"
                                    : "single-column-layout"
                                }`}
                              >
                                <>
                                  {surveyData.length < 1 ? (
                                    <p className="text-center m-0 py-5">Nothing to preview</p>
                                  ) : (
                                    <>
                                      {surveyData.map((data, index) => {
                                        if (data?.ques_type === DRAGGABLE_FORM_CONSTANTS.HEADER) {
                                          headerRef.current = true
                                          c++
                                        } else {
                                          headerRef.current = false
                                        }
                                        // const uniqueKey = `${data.ques_no}_${data.ques_type}_${index}`;

                                        const uniqueKey = data.ques_no != "name" ? data.ques_no : ""

                                        return (
                                          <>
                                            <div
                                              className={
                                                headerRef.current
                                                  ? `question-wrapper single-column-question_${
                                                      c % 2 === 0 ? "0" : "1"
                                                    }`
                                                  : `white-wrapper${c % 2 === 0 ? "0" : "1"}`
                                              }
                                            >
                                              {data?.ques_type ==
                                                DRAGGABLE_FORM_CONSTANTS.HEADER && (
                                                <h5 className="question-title mb-0 col-lg-12">
                                                  {" "}
                                                  {data.ques}
                                                </h5>
                                              )}

                                              {data?.ques_type ==
                                                DRAGGABLE_FORM_CONSTANTS.TEXTBOX && (
                                                <div className="questions-block" key={uniqueKey}>
                                                  <span className="question-label">
                                                    <strong>Q{data.ques_no.split("_")[1]}. </strong>
                                                    {data.ques}
                                                    {data.item.validation.required == 1 ? " *" : ""}
                                                  </span>
                                                  <CommonTextInput
                                                    {...register(uniqueKey)}
                                                    value={getValues(uniqueKey)}
                                                    className="form-control text-input"
                                                    type="text"
                                                    disabled={true}
                                                    label={false}
                                                    name={uniqueKey}
                                                    placeholder="Enter Your Text"
                                                    loginError={errors}
                                                    file={`false`}
                                                    textarea={`false`}
                                                    setIsShow={`false`}
                                                  />
                                                  {(data.item.validation.numeric == 1 ||
                                                    data.item.validation.numeric == true) && (
                                                    <p>Only numbers allowed</p>
                                                  )}
                                                </div>
                                              )}
                                              {data?.ques_type ==
                                                DRAGGABLE_FORM_CONSTANTS.TEXTAREA && (
                                                <div className="questions-block">
                                                  <span className="question-label">
                                                    {" "}
                                                    <strong>Q{data.ques_no.split("_")[1]}. </strong>
                                                    {data.ques}
                                                    {data.item.validation.required == 1 ? " *" : ""}
                                                  </span>
                                                  <CommonTextInput
                                                    {...register(uniqueKey)}
                                                    value={getValues(uniqueKey)}
                                                    className="form-control"
                                                    type="textarea"
                                                    label={false}
                                                    disabled={true}
                                                    name={uniqueKey}
                                                    placeholder="Enter Your Text"
                                                    loginError={errors}
                                                    file={`false`}
                                                    textarea={`true`}
                                                    setIsShow={`false`}
                                                  />
                                                </div>
                                              )}
                                              {data?.ques_type ===
                                                DRAGGABLE_FORM_CONSTANTS.CHECKBOX && (
                                                <div className="questions-block" key={uniqueKey}>
                                                  <div className="field-rep-block checkbox-list">
                                                    <CheckBox
                                                      label={false}
                                                      disabled={true}
                                                      checked={
                                                        getValues(uniqueKey) === "Yes" ||
                                                        data?.ans === "1"
                                                      }
                                                      onChange={(e) => {
                                                        const value = e.target.checked
                                                          ? "Yes"
                                                          : "No"
                                                        setValue(uniqueKey, value)
                                                      }}
                                                    />
                                                  </div>
                                                  <span className="question-label ms-4">
                                                    <strong>
                                                      Q{data?.ques_no?.split("_")[1]}.{" "}
                                                    </strong>
                                                    {data?.ques}
                                                    {data?.item?.validation?.required == 1
                                                      ? " *"
                                                      : ""}
                                                  </span>
                                                  {errors[uniqueKey] && (
                                                    <p className="error">
                                                      {errors[uniqueKey].message}
                                                    </p>
                                                  )}
                                                </div>
                                              )}

                                              {data?.ques_type ==
                                                DRAGGABLE_FORM_CONSTANTS.CHECKBOXGRP && (
                                                <div className="questions-block">
                                                  <span className="question-label">
                                                    {" "}
                                                    <strong>Q{data.ques_no.split("_")[1]}. </strong>
                                                    {data.ques}
                                                    {data.item.validation.required == 1 ? " *" : ""}
                                                  </span>
                                                  <div className="field-rep-block checkbox-list preview-checkbox-grp">
                                                    {data?.item?.options?.map((ele, i) => {
                                                      return (
                                                        <div className="field-radio-block">
                                                          <label className="checkbox checkbox-outline-primary mb-0">
                                                            <input
                                                              type="checkbox"
                                                              disabled={true}
                                                              checked={
                                                                getValues(uniqueKey)?.includes(
                                                                  ele.exported_value
                                                                ) || false
                                                              }
                                                            />
                                                            <span>{ele.display_value}</span>
                                                            <span className="checkmark"></span>
                                                          </label>
                                                        </div>
                                                      )
                                                    })}
                                                  </div>
                                                  {errors[uniqueKey] && (
                                                    <p className="error">
                                                      {errors[uniqueKey].message}
                                                    </p>
                                                  )}
                                                </div>
                                              )}
                                              {data?.ques_type ==
                                                DRAGGABLE_FORM_CONSTANTS.SELECT && (
                                                <div className="questions-block">
                                                  <span className="question-label">
                                                    <strong>Q{data.ques_no.split("_")[1]}. </strong>
                                                    {data.ques}
                                                    {data.item.validation.required == 1 ? " *" : ""}
                                                  </span>
                                                  <select
                                                    className="form-select nice-select-form bg-white"
                                                    {...register(uniqueKey)}
                                                    disabled={true}
                                                    onChange={(e) => {
                                                      const selectedValue = e.target.value
                                                      setValue(uniqueKey, selectedValue)
                                                    }}
                                                  >
                                                    {data?.item?.options?.map((itm, i) => (
                                                      <option
                                                        key={i}
                                                        value={itm.exported_value}
                                                        selected={
                                                          getValues(uniqueKey) == itm.display_value
                                                        }
                                                      >
                                                        {itm.display_value}
                                                      </option>
                                                    ))}
                                                  </select>
                                                  {errors[uniqueKey] && (
                                                    <p className="error">
                                                      {errors[uniqueKey].message}
                                                    </p>
                                                  )}
                                                </div>
                                              )}
                                              {data?.ques_type ==
                                                DRAGGABLE_FORM_CONSTANTS.RADIO && (
                                                <div className="questions-block">
                                                  <span className="question-label">
                                                    <strong>Q{data.ques_no.split("_")[1]}. </strong>{" "}
                                                    {data.ques}
                                                    {data.item.validation.required == 1 ? " *" : ""}
                                                  </span>
                                                  <div className="preview-radio-grp">
                                                    {data?.item?.options?.map((ele, i) => {
                                                      return (
                                                        <>
                                                          <div
                                                            className="field-radio-block"
                                                            key={i}
                                                          >
                                                            <label className="radio radio-outline-primary field-radio mb-0">
                                                              <input
                                                                type="radio"
                                                                disabled={true}
                                                                name={uniqueKey}
                                                                checked={
                                                                  getValues(uniqueKey) ===
                                                                  ele.exported_value
                                                                }
                                                                onChange={() =>
                                                                  setValue(
                                                                    uniqueKey,
                                                                    ele.exported_value
                                                                  )
                                                                }
                                                              />
                                                              <span>{ele.display_value}</span>
                                                              <span className="checkmark"></span>
                                                            </label>
                                                            {errors[uniqueKey] && (
                                                              <p className="error">
                                                                {errors[uniqueKey].message}
                                                              </p>
                                                            )}
                                                          </div>
                                                        </>
                                                      )
                                                    })}
                                                  </div>
                                                </div>
                                              )}
                                              {data?.ques_type == DRAGGABLE_FORM_CONSTANTS.DATE && (
                                                <div className="questions-block">
                                                  <span className="question-label">
                                                    <strong>Q{data.ques_no.split("_")[1]}. </strong>{" "}
                                                    {data.ques}
                                                    {data.item.validation.required == 1 ? " *" : ""}
                                                  </span>
                                                  <div className="input-group date-picker">
                                                    <FormFieldDatePickers
                                                      label={false}
                                                      name={uniqueKey}
                                                      disabled={true}
                                                      register={register}
                                                      control={control}
                                                      placeholder="Select date"
                                                      // minDate={today}
                                                      required
                                                      className="w-100 form-control date-input"
                                                    />
                                                  </div>
                                                </div>
                                              )}

                                              {data?.ques_type == DRAGGABLE_FORM_CONSTANTS.FILE && (
                                                <div className="questions-block">
                                                  <span className="question-label">
                                                    {" "}
                                                    <strong>
                                                      Q{data.ques_no.split("_")[1]}.{" "}
                                                    </strong>{" "}
                                                    {data.ques}
                                                    {data.item.validation.required == 1 ? " *" : ""}
                                                  </span>
                                                  <div className="input-group custom-file-button">
                                                    <CommonTextInputPrev
                                                      type="file"
                                                      disabled={true}
                                                      name={uniqueKey}
                                                      label={false}
                                                      placeholder="Choose File"
                                                      className="file-form"
                                                      loginError={errors}
                                                      file={`true`}
                                                      textarea={`false`}
                                                      path={getValues(uniqueKey)}
                                                      accept={data?.item?.accept}
                                                      filesallow={data?.item?.accept}
                                                      // logoDelete={() => deleteImage(uniqueKey)}
                                                      id={uniqueKey}
                                                    />
                                                  </div>
                                                </div>
                                              )}
                                              {data?.ques_type ==
                                                DRAGGABLE_FORM_CONSTANTS.INVOICE && (
                                                <div className="questions-block" key={uniqueKey}>
                                                  <span className="question-label">
                                                    <strong>Q{data.ques_no.split("_")[1]}. </strong>
                                                    {data.ques}
                                                  </span>
                                                  <CommonTextInput
                                                    {...register(uniqueKey)}
                                                    value={getValues(uniqueKey)}
                                                    className="form-control text-input"
                                                    type="number"
                                                    disabled={true}
                                                    label={false}
                                                    name={uniqueKey}
                                                    placeholder={data.ques}
                                                    loginError={errors}
                                                    file={`false`}
                                                    textarea={`false`}
                                                    setIsShow={`false`}
                                                  />
                                                </div>
                                              )}
                                            </div>
                                          </>
                                        )
                                      })}
                                    </>
                                  )}
                                </>

                                <div
                                  className={`template-button-block bg-white hide-in-pdf bottom-radius`}
                                >
                                  <div className="d-flex w-100 parent-btn-grp">
                                    {(surveyDetail?.status == "reported" ||
                                      surveyDetail?.status == "late") && (
                                      <div className="conditionalBtns me-auto">
                                        {/* <button
                                          type="button"
                                          className="btn btn-primary"
                                          onClick={() => navigate(`/surveys/preview/edit/${id}`)}
                                        >
                                          
                                        </button> */}
                                        <Button
                                          className="btn save-btn m-0"
                                          onClick={async () => {
                                            const permission = await checkPermission("edit_survey")
                                            if (!permission) {
                                              dispatch(
                                                showUpdatedToasterMessage({
                                                  message: Denied_Msg,
                                                  type: "error"
                                                })
                                              )
                                              return
                                            }
                                            navigate(`/surveys/preview/edit/${id}/${status}`)
                                          }}
                                          // to={`/surveys/preview/edit/${id}/${status}`}
                                          state={{
                                            previewstatus: surveyDetail?.status
                                          }}
                                        >
                                          Edit Survey
                                        </Button>
                                        <button
                                          type="button"
                                          className="btn btn-danger mx-4 font-14 fw-bold"
                                          onClick={async () => {
                                            const permission = await checkPermission("edit_survey")
                                            if (!permission) {
                                              dispatch(
                                                showUpdatedToasterMessage({
                                                  message: Denied_Msg,
                                                  type: "error"
                                                })
                                              )
                                              return
                                            }
                                            setModal({
                                              ...modal,
                                              show: true,
                                              title: "Unapprove Survey",
                                              description:
                                                "Do you really want to mark this survey as unapproved?",
                                              action: "refuseSurvey"
                                            })
                                          }}
                                        >
                                          Mark As Not Approved
                                        </button>
                                        <button
                                          type="button"
                                          className="btn btn-success"
                                          onClick={async () => {
                                            const permission = await checkPermission("edit_survey")
                                            if (!permission) {
                                              dispatch(
                                                showUpdatedToasterMessage({
                                                  message: Denied_Msg,
                                                  type: "error"
                                                })
                                              )
                                              return
                                            }
                                            setModal({
                                              ...modal,
                                              show: true,
                                              title: "Approve Survey",
                                              description:
                                                "Do you really want to approve this survey?",
                                              action: "approveSurvey"
                                            })
                                          }}
                                        >
                                          Approve
                                        </button>
                                      </div>
                                    )}
                                    <button
                                      type="button"
                                      onClick={exportToPDF}
                                      disabled={loadingExport}
                                      className="btn save-btn"
                                    >
                                      {loadingExport ? `Exporting...${progress}%` : "Export"}
                                    </button>
                                    {/* <button
                                      type="button"
                                      className="btn save-btn"
                                      onClick={exportToPDF}
                                    >
                                      Export
                                    </button> */}
                                    <button className="btn cancel-btn" onClick={goBack}>
                                      cancel
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Form>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Modal
            size="lg"
            show={notes}
            // onHide={handleCloseDeleteModal}
          >
            <Modal.Header>
              <Modal.Title className="d-flex align-items-center justify-content-between w-100">
                <span>Add Survey note</span>
                <button type="button" className="btn-close" onClick={() => setNotes(false)}>
                  <img src={closeIcon} alt="ARS" />
                </button>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="modal-content">
                {/* <p>are you sure you want to reject this offer</p> */}
                <div className="instruction-block modal-form">
                  <p className="m-0">Survey Note</p>
                  <div className="issue-type-radio p-0 mt-0 mb-0 border-0 d-flex flex-column gap-2">
                    <div className="form-block">
                      <div>
                        <CommonTextInput
                          className={`form-control h-auto undefined form-control pt-2 mt-2`}
                          label={false}
                          type="textarea"
                          name="notes"
                          placeholder="Enter survey note"
                          textarea={`true`}
                          file={`false`}
                          rows={4}
                          onChange={(e) => setNote(e.target.value)}
                          cols={5}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="modal-button-block">
                    <Button
                      type="button"
                      className="btn submit-btn btn btn-primary"
                      onClick={handleNoteSave}
                      // disabled={!reason}
                    >
                      Submit
                    </Button>
                    <Button
                      type="button"
                      className="btn cancel-btn btn btn-primary"
                      onClick={() => setNotes(false)}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </main>
      )}
    </>
  )
}
export default PreviewSurvey
