import React, { forwardRef } from "react";

import AsyncSelect from "react-select/async";
import CreatableSelect from "react-select/creatable";
import Select, { components } from "react-select";

import { IconChevronDown } from "@tabler/icons-react";
import { Badge } from "react-bootstrap";

function getSelectStyles(multi, size = "") {
  const suffix = size ? `-${size}` : "";
  const multiplicator = multi ? 2 : 1;
  return {
    control: (provided, { isDisabled, isFocused }) => ({
      ...provided,
    }),
  };
}

function IndicatorSeparator() {
  return null;
}

function DropdownIndicator(props) {
  return (
    <components.DropdownIndicator {...props}>
      <span className="mx-2">
        <IconChevronDown size={18} />
      </span>
    </components.DropdownIndicator>
  );
}

function getSelectTheme(theme) {
  return {
    ...theme,
    borderRadius: "var(--bs-select-border-radius)",
    colors: {
      ...theme.colors,
      borderRadius: 5,
      primary: "var(--tblr-muted)",
    },
  };
}

const SelectBox = forwardRef(
  ({ isCreatable, isAsync, showColor = false, ...props }, ref) => {
    const SelectType = isAsync
      ? AsyncSelect
      : isCreatable
      ? CreatableSelect
      : Select;
    // console.log('isCreatable:',isCreatable)
    const customStyles = {
      option: (provided, state) => {
        // console.log('data:',state.data)
        return {
          ...provided,
          // ...optionSettings,
          background:
            (state.isSelected || state.isFocused) && "rgba(0, 122, 255, 0.05)",
          color: state.isSelected || state.isFocused ? "black" : "black",
          fontSize: 12,
          wordBreak: "break-all",
          borderBottom: "1px solid #e0e0e0",
          "&:focus": {
            color: state.isSelected || state.isFocused ? "black" : "black",
          },
          // color: (state.isSelected || state.isFocused) && "#ffffff",
        };
      },
      valueContainer: (provided, state) => ({
        ...provided,
      }),
      control: (provided, state) => {
        //let hasError = state.selectProps.className.includes("is-invalid");
        /*let errorMessage = hasError
          ? props.loginError?.[props.name]?.label.message
          : "";*/
        return {
          ...provided,
          height: "4rem",
          fontSize: "3.5rem",
          padding: "1px 0px 0px 0px",
          /*  boxShadow: `${hasError && state.isFocused ? "0 0 0 0.25rem rgb(214 57 57 / 25%)" : state.isFocused ? "0 0 0 0.25rem rgb(42 106 115 / 25%)" : ""}`,
        borderColor: `${hasError ? "var(--tblr-danger)" : state.isFocused ? "#90b5e2" : "var(--tblr-border-color)"}`,
        "&:hover": {
          borderColor: `${hasError ? "var(--tblr-danger)" : state.isFocused ? "#90b5e2" : "var(--tblr-border-color)"}`,
        }, */
          "&:hover": {
            borderColor: "#007aff",
            backgroundColor: "#f2f8ff",
          },
          "&:focus": {
            borderColor: "#007aff",
            backgroundColor: "#f2f8ff",
          },
        };
      },
      dropdownIndicator: (provided, state) => ({
        ...provided,
        padding: "2px 10px 10px 10px",
      }),
      clearIndicator: (provided, state) => ({
        ...provided,
      }),
      multiValue: (styles, { data }) => {
        return {
          ...styles,
          backgroundColor: "rgba(var(--tblr-primary-rgb), 0.1)",
          borderRadius: "4px",
          border: "1px solid var(--tblr-primary)",
          fontWeight: "var(--tblr-font-weight-medium)",
        };
      },
      multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: data.color,
      }),
      // multiValueRemove: (styles, { data }) => ({
      //     ...styles,
      //     color: data.color,
      //     ':hover': {
      //         backgroundColor: data.color,
      //         color: 'white',
      //     },
      // })
    };
    const Option = ({ children, ...props }) => {
      return (
        <components.Option {...props}>
          <div className="d-flex">
            {props.data?.color && (
              <Badge
                bg=""
                className="p-2 me-1"
                style={{ background: `${props.data?.color}` }}
              />
            )}
            {children}
          </div>
        </components.Option>
      );
    };
    const ValueContainer = ({ children, ...props }) => {
      // console.log('ValueContainer:',props)
      return (
        <components.ValueContainer {...props}>
          <div className="d-flex align-items-center">
            {props.selectProps?.value?.color && (
              <Badge
                bg=""
                className="p-2 me-1"
                style={{ background: `${props.selectProps?.value?.color}` }}
              />
            )}
            {children}
          </div>
        </components.ValueContainer>
      );
    };
    const customFilterOption = (option, inputValue) =>
      option.label.toLowerCase().includes(inputValue.toLowerCase());
    const customComponents = showColor
      ? { Option, ValueContainer }
      : isCreatable
      ? {
          Option,
          ValueContainer,
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        }
      : {};
    return (
      <SelectType
        // components={{ DropdownIndicator, IndicatorSeparator, ...components }}
        // theme={getSelectTheme}
        components={customComponents}
        styles={customStyles}
        ref={ref}
        placeholder="Select"
        {...props}
        //edited for search on label only
        filterOption={customFilterOption}
      />
    );
  }
);
export default SelectBox;
