import React from "react";
import { Link } from "react-router-dom";
const Breadcrumbs = ({ links,toggleActive,isActive }) => {
  return (
    <div className="row g-0">
      <div className="col-md-12">
        <div className="custom-breadcrumb">
          <nav>
            <ul>
              {links?.map((link, index) => {
                return (
                  <li key={index}>
                    <Link to={link?.path}>
                     {link?.label}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Breadcrumbs;
