import React, { useEffect, useRef, useState } from "react";
import html2pdf from "html2pdf.js";
import * as Yup from "yup";
import _ from "lodash";
import { Helmet } from "react-helmet";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import { useForm } from "react-hook-form";
import { Form } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Loader from "../Loader";
import * as SurveyAPI from "../../api/SurveyAPI";
import Status from "../Status";
import CheckBox from "../Input/CheckBox";

import CommonTextInput, { CommonTextInputPrev } from "../Input/CommonTextInput";
import { FormFieldDatePickers } from "../Input/FormField";
import { DRAGGABLE_FORM_CONSTANTS } from "../../common/constants";
import { showUpdatedToasterMessage } from "../../store/slices/toaster/toasterslice";
import { Denied_Msg } from "../../assets/data/menu";
import moment from "moment";
import { checkPermission } from "../../api/AuthApi";

const PreviewSurveyAddEdit = ({ isActive, permission }) => {
  const clientCode = useSelector(
    (state) => state.ars.authUserReducer.user.client_code
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id, status } = useParams();
  const headerRef = useRef(false);
  let c = 0;
  const [componentKey, setComponentKey] = useState(false);
  const [loading, setLoading] = useState(false);
  const [surveyInvoice, setSurveyInvoice] = useState([]);
  const [surveyDetail, setSurveyDetail] = useState({
    client_name: "",
    project_name: "",
    round_name: "",
    site_name: "",
    site_location: "",
    fieldrep_name: "",
    schedule_date: "",
    status: "",
    service_code: "",
    template_column: "",
  });
  const [surveyData, setSurveyData] = useState([]);
  const today = new Date();
  const generateValidationSchema = () => {
    const validationSchemaObject = {};

    surveyData.forEach((item, index) => {
      // const uniqueKey = `${item.ques_no}_${item.ques_type}_${index}`;
      const uniqueKey = item.ques_no != "name" ? item.ques_no : "";
      switch (item.ques_type) {
        case DRAGGABLE_FORM_CONSTANTS.TEXTBOX:
          validationSchemaObject[uniqueKey] =
            item.item.validation.required == "1"
              ? Yup.string().nullable().required("This field is required")
              : Yup.string().nullable();
          break;
        case DRAGGABLE_FORM_CONSTANTS.TEXTAREA:
          validationSchemaObject[uniqueKey] =
            item.item.validation.required == "1"
              ? Yup.string().nullable().required("This field is required")
              : Yup.string().nullable();
          break;
        case DRAGGABLE_FORM_CONSTANTS.CHECKBOX:
          validationSchemaObject[uniqueKey] =
            item.item.validation.required == "1"
              ? Yup.mixed()
                  .transform((value) =>
                    value === "" || value === "No" ? null : value
                  )
                  .required("This field is required")
              : Yup.mixed();
          break;
        case DRAGGABLE_FORM_CONSTANTS.CHECKBOXGRP:
          validationSchemaObject[uniqueKey] =
            item.item.validation.required == "1"
              ? Yup.mixed()
                  .transform((value) => (value === "" ? null : value))
                  .required("This field is required")
              : Yup.mixed();
          break;
        case DRAGGABLE_FORM_CONSTANTS.SELECT:
          validationSchemaObject[uniqueKey] =
            item.item.validation.required == "1"
              ? Yup.string().nullable().required("This field is required")
              : Yup.string().nullable();
          break;
        case DRAGGABLE_FORM_CONSTANTS.RADIO:
          validationSchemaObject[uniqueKey] =
            item.item.validation.required == "1"
              ? Yup.mixed().required("This field is required")
              : Yup.mixed();
          break;
        case DRAGGABLE_FORM_CONSTANTS.FILE:
          validationSchemaObject[uniqueKey] =
            item.item.validation.required == "1"
              ? Yup.mixed().test(
                  "required",
                  "This field is required",
                  (value) => !!value
                )
              : Yup.mixed();
          break;
        case DRAGGABLE_FORM_CONSTANTS.DATE:
          validationSchemaObject[uniqueKey] =
            item.item.validation.required == "1"
              ? Yup.string()
                  .nullable()
                  .required("This field is required")
                  .transform(function (value, originalValue) {
                    if (originalValue) {
                      return moment(originalValue).format("MM/DD/YYYY");
                    }
                    return null;
                  })
              : Yup.string()
                  .nullable()
                  .transform(function (value, originalValue) {
                    if (originalValue) {
                      return moment(originalValue).format("MM/DD/YYYY");
                    }
                    return null;
                  });
          break;
        // case DRAGGABLE_FORM_CONSTANTS.DATE:
        //   validationSchemaObject[uniqueKey] =
        //     item.item.validation.required == "1"
        //       ? Yup.date().nullable().required("This field is required")
        //       : Yup.date().nullable()
        //   break
        default:
          break;
      }
    });

    return Yup.object().shape(validationSchemaObject);
  };

  const validationSchema = generateValidationSchema();

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    control,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(validationSchema),
    defaultValues: {},
  });

  const watching = watch();

  useEffect(() => {
    if (surveyDetail?.survey_id) {
      if (surveyInvoice?.length > 0) {
        surveyInvoice.forEach((item) => {
          if (item.ques_type === "number") {
            setValue(item.ques_no, surveyDetail.survey_id);
          }
        });
      }
    }
  }, [surveyDetail, surveyInvoice]);

  const focusss = () => {
    const firstErrorKey = Object.keys(errors)[0];
    const firstInvalidField = document.getElementsByName(firstErrorKey)[0];
    if (firstInvalidField) {
      firstInvalidField.focus();
    }
  };
  useEffect(() => {
    focusss();
  }, [errors]);
  const onSubmit = async (data) => {
    const newData = data;
    Object.keys(newData).forEach((key) => {
      if (key === "" || newData[key] === undefined) {
        delete newData[key];
      }
    });
    const formData = new FormData();
    Object.keys(newData).forEach((key) => {
      formData.append(key, newData[key]);
    });
    formData.append("status", 1);
    formData.append("client_code", clientCode);
    formData.append("survey_id", id);

    try {
      const permission = await checkPermission("edit_survey");
      if (!permission) {
        dispatch(
          showUpdatedToasterMessage({
            message: Denied_Msg,
            type: "danger",
          })
        );
        return;
      }
      const response = await SurveyAPI.editPreview(formData);
      if (response.status == true) {
        //fetchPreviewData();
        navigate(`/surveys/preview/${id}/${status}`);
      }
    } catch (error) {
      dispatch(
        showUpdatedToasterMessage({
          message: error.message,
          type: "danger",
        })
      );
    } finally {
      fetchPreviewData();
    }
  };

  const fetchPreviewData = async () => {
    setLoading(true);
    try {
      const response = await SurveyAPI.previewData(id, status);
      if (response.status == true) {
        setSurveyInvoice(response.data);
        setSurveyDetail(response.data[response.data.length - 1]);
        const formattedData = _.map(response?.data, (item) => {
          if (item.ques_type === "date" && item.ans) {
            item.ans = moment(item.ans, "DD-MMM-YYYY").format("MM/DD/YYYY");
          }
          return item;
        });
        setSurveyData(formattedData);

        const defaultValues = {};

        formattedData.forEach((item, index) => {
          const uniqueKey = item.ques_no != "name" ? item.ques_no : "";
          defaultValues[uniqueKey] =
            item.ans == "null" || null ? null : item.ans;
        });

        Object.keys(defaultValues).forEach((key) => {
          setValue(key, defaultValues[key]);
        });
      }
    } catch (error) {
      dispatch(
        showUpdatedToasterMessage({
          message: error.message,
          type: "danger",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchPreviewData();
  }, []);

  const goBack = () => {
    navigate(-1);
  };
  const validateFile = async (formKey, e, acceptType) => {
    if (!e.target.files[0]) {
      return;
    }
    const selectedFile = e.target.files[0];
    let acceptedTypes = [
      "image/jpeg",
      "image/png",
      "image/jpg",
      "text/plain",
      "application/pdf",
    ];
    if (acceptType.includes(".jpg") && acceptType.includes(".pdf")) {
      acceptedTypes = [
        "image/jpeg",
        "image/png",
        "image/jpg",
        "text/plain",
        "application/pdf",
      ];
    } else if (acceptType.includes(".jpg") && !acceptType.includes(".pdf")) {
      acceptedTypes = ["image/jpeg", "image/png", "image/jpg"];
    } else {
      acceptedTypes = ["text/plain", "application/pdf"];
    }

    if (!acceptedTypes.includes(selectedFile.type)) {
      setError(formKey, {
        message: "Invalid file type",
      });
      e.target.value = null;
      setValue(formKey, "");
    } else {
      uploadImage(formKey, e.target.files[0]);
    }
  };
  const uploadImage = async (formKey, file) => {
    const formData = new FormData();
    formData.append(formKey, file);
    formData.append("status", 0);
    formData.append("client_code", clientCode);
    formData.append("survey_id", id);
    try {
      const response = await SurveyAPI.editPreview(formData);
      //if (response.status == true) {
      setValue(response?.data[0][0].ques_no, response?.data[0][0].answer);
      clearErrors(formKey);
      //  }
    } catch (error) {
      dispatch(
        showUpdatedToasterMessage({
          message: error.message,
          type: "danger",
        })
      );
    }
  };

  const deleteImage = async (name) => {
    try {
      const response = await SurveyAPI.removePreviewImage(id, name, clientCode);
      if (response.status == true) {
        setValue(name, null);
      }
    } catch (error) {
      dispatch(
        showUpdatedToasterMessage({
          message: error.message,
          type: "success",
        })
      );
    }
  };

  const handleChecked = (uniqueKey, exportedValue) => {
    const currentValues =
      getValues(uniqueKey) == null ? "" : getValues(uniqueKey);

    if (currentValues !== null && currentValues !== undefined) {
      const updatedAnswer = currentValues.includes(exportedValue)
        ? currentValues
            .split(",")
            .filter((value) => value !== exportedValue)
            .join(",")
        : currentValues
        ? `${currentValues},${exportedValue}`
        : exportedValue;

      setValue(uniqueKey, updatedAnswer);
      if (updatedAnswer.length > 0) {
        clearErrors(uniqueKey);
      }
    } else {
      setValue(uniqueKey, "");
    }
  };
  useDispatch(() => {
    setComponentKey(!componentKey);
  }, [watching]);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <main className={`${isActive && "active"}`}>
          <section className="dashboard">
          <Helmet>
            <title>ARS | Survey Details</title>
          </Helmet>
          <Breadcrumbs
            links={[
              { label: "Home", path: "/" },
              { label: "Surveys", path: "/surveys" },
              { label: "Survey Details"},
            ]}
          />
            <div className="survey-questions" id="previewPdf">
              <div className="container-fluid p-0">
                <div className="row g-0 justify-content-center">
                  <div className="col-md-12">
                    <div className="card-shadow radius-5">
                      <div className="survey-header top-radius">
                        <div className="survey-title">
                          <h5>Survey Details</h5>
                        </div>
                      </div>
                      <div className="user-detail-card mb-20 single-column-detail bottom-radius">
                        <div className="user-detail-wrapper">
                          <div className="user-data">
                            <small>Client</small>
                            <span>:</span>
                            <h6>{surveyDetail?.client_name}</h6>
                          </div>
                          <div className="user-data">
                            <small>Round</small>
                            <span>:</span>
                            <h6>{surveyDetail?.round_name}</h6>
                          </div>
                          <div className="user-data">
                            <small>Site Location</small>
                            <span>:</span>
                            <h6>{surveyDetail?.site_location}</h6>
                          </div>
                          <div className="user-data">
                            <small>Schedule Date &amp; Time</small>
                            <span>:</span>
                            <h6>
                              {surveyDetail?.schedule_date
                                ? moment(surveyDetail?.schedule_date).format(
                                    "MM/DD/YYYY"
                                  )
                                : null}
                            </h6>
                          </div>
                          {surveyDetail.status == "approved" ? (
                            <div className="user-data">
                              <small>Approved Date</small>
                              <span>:</span>
                              <h6>
                                {surveyDetail?.approved_date
                                  ? moment(surveyDetail?.approved_date).format(
                                      "MM/DD/YYYY"
                                    )
                                  : null}
                              </h6>
                            </div>
                          ) : (
                            ""
                          )}

                          <div className="user-data">
                            <small>Project</small>
                            <span>:</span>
                            <h6>{surveyDetail?.project_name}</h6>
                          </div>
                          <div className="user-data">
                            <small>Site</small>
                            <span>:</span>
                            <h6>{surveyDetail?.site_name}</h6>
                          </div>
                          <div className="user-data">
                            <small>FieldRep</small>
                            <span>:</span>
                            <h6>
                              {surveyDetail?.fieldrep_name
                                ? surveyDetail?.fieldrep_name
                                : " - "}
                            </h6>
                          </div>
                          <div className="user-data">
                            <small>FieldRep Code</small>
                            <span>:</span>
                            <h6>
                              {surveyDetail?.fieldrep_code
                                ? surveyDetail?.fieldrep_code
                                : " - "}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-shadow radius-5">
                      <div className="survey-header top-radius">
                        <div className="survey-title">
                          <h5>Fill Survey</h5>
                          <span>
                            Service Key :{" "}
                            <strong>{surveyDetail?.service_code || "  -"}</strong>
                          </span>
                        </div>

                        <div className="survey-badge">
                          <Status status={surveyDetail?.status} />
                        </div>
                      </div>
                      <section className="survey-questions bg-white bottom-radius">
                        <div className="container-fluid p-0">
                          <Form
                            onSubmit={handleSubmit(onSubmit)}
                            autoComplete="off"
                            className="card-form survey-preview-form"
                          >
                            <div className="row g-0 justify-content-center">
                              <div
                                className={`col-md-12 ${
                                  surveyDetail?.template_column == 2
                                    ? "double-column-layout"
                                    : "single-column-layout"
                                }`}
                              >
                                <>
                                  {surveyData.length < 1 ? (
                                    <p className="text-center m-0 py-5">
                                      Nothing to preview
                                    </p>
                                  ) : (
                                    <>
                                      {surveyData.map((data, index) => {
                                        if (
                                          data?.ques_type ===
                                          DRAGGABLE_FORM_CONSTANTS.HEADER
                                        ) {
                                          headerRef.current = true;
                                          c++;
                                        } else {
                                          headerRef.current = false;
                                        }
                                        // const uniqueKey = `${data.ques_no}_${data.ques_type}_${index}`;

                                        const uniqueKey =
                                          data.ques_no != "name"
                                            ? data.ques_no
                                            : "";

                                        return (
                                          <>
                                            <div
                                              className={
                                                headerRef.current
                                                  ? `question-wrapper single-column-question_${
                                                      c % 2 === 0 ? "0" : "1"
                                                    }`
                                                  : `white-wrapper${
                                                      c % 2 === 0 ? "0" : "1"
                                                    }`
                                              }
                                            >
                                              {data?.ques_type ==
                                                DRAGGABLE_FORM_CONSTANTS.HEADER && (
                                                <h5 className="question-title mb-0 col-lg-12">
                                                  {" "}
                                                  {data.ques}
                                                </h5>
                                              )}

                                              {data?.ques_type ==
                                                DRAGGABLE_FORM_CONSTANTS.TEXTBOX && (
                                                <div
                                                  className="questions-block"
                                                  key={uniqueKey}
                                                >
                                                  <span className="question-label">
                                                    <strong>
                                                      Q
                                                      {data.ques_no.split("_")[1]}
                                                      .{" "}
                                                    </strong>
                                                    {data.ques}
                                                    {data.item.validation
                                                      .required == 1
                                                      ? " *"
                                                      : ""}
                                                  </span>
                                                  <CommonTextInput
                                                    {...register(uniqueKey)}
                                                    value={getValues(uniqueKey)}
                                                    className="form-control"
                                                    type={
                                                      data.item.validation.numeric
                                                        ? "number"
                                                        : "text"
                                                    }
                                                    label={false}
                                                    name={uniqueKey}
                                                    placeholder="Enter Your Text"
                                                    loginError={errors}
                                                    file={`false`}
                                                    textarea={`false`}
                                                    setIsShow={`false`}
                                                  />
                                                  {(data.item.validation
                                                    .numeric == 1 ||
                                                    data.item.validation
                                                      .numeric == true) && (
                                                    <p>Only numbers allowed</p>
                                                  )}
                                                </div>
                                              )}
                                              {data?.ques_type ==
                                                DRAGGABLE_FORM_CONSTANTS.TEXTAREA && (
                                                <div className="questions-block">
                                                  <span className="question-label">
                                                    {" "}
                                                    <strong>
                                                      Q
                                                      {data.ques_no.split("_")[1]}
                                                      .{" "}
                                                    </strong>
                                                    {data.ques}
                                                    {data.item.validation
                                                      .required == 1
                                                      ? " *"
                                                      : ""}
                                                  </span>
                                                  <CommonTextInput
                                                    {...register(uniqueKey)}
                                                    value={getValues(uniqueKey)}
                                                    className="form-control"
                                                    type="textarea"
                                                    label={false}
                                                    name={uniqueKey}
                                                    placeholder="Enter Your Text"
                                                    loginError={errors}
                                                    file={`false`}
                                                    textarea={`true`}
                                                    setIsShow={`false`}
                                                  />
                                                  {errors[uniqueKey] && (
                                                    <p className="invalid-feedback fs-4">
                                                      {errors[uniqueKey].message}
                                                    </p>
                                                  )}
                                                </div>
                                              )}
                                              {data?.ques_type ===
                                                DRAGGABLE_FORM_CONSTANTS.CHECKBOX && (
                                                <div
                                                  className="questions-block"
                                                  key={uniqueKey}
                                                >
                                                  <div className="field-rep-block checkbox-list">
                                                    <CheckBox
                                                      label={false}
                                                      checked={
                                                        getValues(uniqueKey) ===
                                                        "Yes"
                                                      }
                                                      onChange={(e) => {
                                                        const value = e.target
                                                          .checked
                                                          ? "Yes"
                                                          : "No";
                                                        setValue(
                                                          uniqueKey,
                                                          value
                                                        );
                                                        clearErrors(uniqueKey);
                                                      }}
                                                    />
                                                  </div>
                                                  <span className="question-label ms-4">
                                                    <strong>
                                                      Q
                                                      {data.ques_no.split("_")[1]}
                                                      .{" "}
                                                    </strong>
                                                    {data.ques}
                                                    {data.item.validation
                                                      .required == 1
                                                      ? " *"
                                                      : ""}
                                                  </span>
                                                  {errors[uniqueKey] && (
                                                    <p className="invalid-feedback fs-4">
                                                      {errors[uniqueKey].message}
                                                    </p>
                                                  )}
                                                </div>
                                              )}

                                              {data?.ques_type ==
                                                DRAGGABLE_FORM_CONSTANTS.CHECKBOXGRP && (
                                                <div className="questions-block">
                                                  <span className="question-label">
                                                    {" "}
                                                    <strong>
                                                      Q
                                                      {data.ques_no.split("_")[1]}
                                                      .{" "}
                                                    </strong>
                                                    {data.ques}
                                                    {data.item.validation
                                                      .required == 1
                                                      ? " *"
                                                      : ""}
                                                  </span>
                                                  <div className="field-rep-block checkbox-list preview-checkbox-grp">
                                                    {data?.item?.options?.map(
                                                      (ele, i) => {
                                                        return (
                                                          <div className="field-radio-block">
                                                            <label className="checkbox checkbox-outline-primary mb-0">
                                                              <input
                                                                type="checkbox"
                                                                checked={
                                                                  getValues(
                                                                    uniqueKey
                                                                  )?.includes(
                                                                    ele.exported_value
                                                                  ) || false
                                                                }
                                                                // checked={getValues(uniqueKey) ?
                                                                //   getValues(uniqueKey)?.includes(ele.exported_value) : false
                                                                //  }
                                                                onChange={(e) => {
                                                                  handleChecked(
                                                                    uniqueKey,
                                                                    ele.exported_value
                                                                  );
                                                                }}
                                                              />
                                                              <span>
                                                                {
                                                                  ele.display_value
                                                                }
                                                              </span>
                                                              <span className="checkmark"></span>
                                                            </label>
                                                          </div>
                                                        );
                                                      }
                                                    )}
                                                  </div>
                                                  {errors[uniqueKey] && (
                                                    <p className="invalid-feedback fs-4">
                                                      {errors[uniqueKey].message}
                                                    </p>
                                                  )}
                                                </div>
                                              )}
                                              {data?.ques_type ==
                                                DRAGGABLE_FORM_CONSTANTS.SELECT && (
                                                <div className="questions-block">
                                                  <span className="question-label">
                                                    <strong>
                                                      Q
                                                      {data.ques_no.split("_")[1]}
                                                      .{" "}
                                                    </strong>
                                                    {data.ques}
                                                    {data.item.validation
                                                      .required == 1
                                                      ? " *"
                                                      : ""}
                                                  </span>
                                                  <select
                                                    className="form-select nice-select-form bg-white"
                                                    {...register(uniqueKey)}
                                                    onChange={(e) => {
                                                      const selectedValue =
                                                        e.target.value;
                                                      setValue(
                                                        uniqueKey,
                                                        selectedValue
                                                      );
                                                      clearErrors(uniqueKey);
                                                    }}
                                                  >
                                                    {data?.item?.options?.map(
                                                      (itm, i) => (
                                                        <option
                                                          key={i}
                                                          value={
                                                            itm.exported_value
                                                          }
                                                          selected={
                                                            getValues(
                                                              uniqueKey
                                                            ) == itm.display_value
                                                          }
                                                        >
                                                          {itm.display_value}
                                                        </option>
                                                      )
                                                    )}
                                                  </select>
                                                  {errors[uniqueKey] && (
                                                    <p className="invalid-feedback fs-4">
                                                      {errors[uniqueKey].message}
                                                    </p>
                                                  )}
                                                </div>
                                              )}
                                              {data?.ques_type ==
                                                DRAGGABLE_FORM_CONSTANTS.RADIO && (
                                                <div className="questions-block">
                                                  <span className="question-label">
                                                    <strong>
                                                      Q
                                                      {data.ques_no.split("_")[1]}
                                                      .{" "}
                                                    </strong>{" "}
                                                    {data.ques}
                                                    {data.item.validation
                                                      .required == 1
                                                      ? " *"
                                                      : ""}
                                                  </span>
                                                  <div className="preview-radio-grp">
                                                    {data?.item?.options?.map(
                                                      (ele, i) => {
                                                        return (
                                                          <div
                                                            className="field-radio-block"
                                                            key={i}
                                                          >
                                                            <label className="radio radio-outline-primary field-radio mb-0">
                                                              <input
                                                                type="radio"
                                                                name={uniqueKey}
                                                                checked={
                                                                  getValues(
                                                                    uniqueKey
                                                                  ) ===
                                                                  ele.exported_value
                                                                }
                                                                onChange={() => {
                                                                  setValue(
                                                                    uniqueKey,
                                                                    ele.exported_value
                                                                  );
                                                                  setComponentKey(
                                                                    !componentKey
                                                                  );
                                                                  clearErrors(
                                                                    uniqueKey
                                                                  );
                                                                }}
                                                              />
                                                              <span>
                                                                {
                                                                  ele.display_value
                                                                }
                                                              </span>
                                                              <span className="checkmark"></span>
                                                            </label>
                                                          </div>
                                                        );
                                                      }
                                                    )}
                                                    {errors[uniqueKey] && (
                                                      <p className="invalid-feedback fs-4">
                                                        {
                                                          errors[uniqueKey]
                                                            .message
                                                        }
                                                      </p>
                                                    )}
                                                  </div>
                                                </div>
                                              )}
                                              {data?.ques_type ==
                                                DRAGGABLE_FORM_CONSTANTS.DATE && (
                                                <div
                                                  className={`questions-block ${uniqueKey}`}
                                                >
                                                  <span className="question-label">
                                                    <strong>
                                                      Q
                                                      {data.ques_no.split("_")[1]}
                                                      .{" "}
                                                    </strong>{" "}
                                                    {data.ques}
                                                    {data.item.validation
                                                      .required == 1
                                                      ? " *"
                                                      : ""}
                                                  </span>
                                                  <div className="input-group date-picker">
                                                    <FormFieldDatePickers
                                                      label={false}
                                                      setValue={setValue}
                                                      name={uniqueKey}
                                                      // register={register}
                                                      control={control}
                                                      placeholder="Select date"
                                                      // minDate={today}
                                                      maxDate={today}
                                                      required
                                                      className="w-100 form-control"
                                                    />
                                                  </div>
                                                  {errors[uniqueKey] && (
                                                    <p className="invalid-feedback fs-4">
                                                      {errors[uniqueKey].message}
                                                    </p>
                                                  )}
                                                </div>
                                              )}

                                              {data?.ques_type ==
                                                DRAGGABLE_FORM_CONSTANTS.FILE && (
                                                <div className="questions-block">
                                                  <span className="question-label">
                                                    {" "}
                                                    <strong>
                                                      Q
                                                      {data.ques_no.split("_")[1]}
                                                      .{" "}
                                                    </strong>{" "}
                                                    {data.ques}
                                                    {data.item.validation
                                                      .required == 1
                                                      ? " *"
                                                      : ""}
                                                  </span>
                                                  <div className="input-group custom-file-button">
                                                    <CommonTextInputPrev
                                                      type="file"
                                                      onChange={(e) => {
                                                        validateFile(
                                                          uniqueKey,
                                                          e,
                                                          data?.item?.accept
                                                        );
                                                      }}
                                                      name={uniqueKey}
                                                      label={false}
                                                      placeholder="Choose File"
                                                      className="file-form"
                                                      loginError={errors}
                                                      file={`true`}
                                                      textarea={`false`}
                                                      path={getValues(uniqueKey)}
                                                      accept={data?.item?.accept}
                                                      filesallow={
                                                        data?.item?.accept
                                                      }
                                                      logoDelete={() =>
                                                        deleteImage(uniqueKey)
                                                      }
                                                      id={uniqueKey}
                                                    />
                                                  </div>
                                                </div>
                                              )}
                                              {data?.ques_type ==
                                                DRAGGABLE_FORM_CONSTANTS.INVOICE && (
                                                <div
                                                  className="questions-block"
                                                  key={uniqueKey}
                                                >
                                                  <span className="question-label">
                                                    <strong>
                                                      Q
                                                      {data.ques_no.split("_")[1]}
                                                      .{" "}
                                                    </strong>
                                                    {data.ques}
                                                  </span>
                                                  <CommonTextInput
                                                    {...register(uniqueKey)}
                                                    className="form-control text-input"
                                                    type="text"
                                                    disabled={true}
                                                    label={false}
                                                    name={uniqueKey}
                                                    placeholder={
                                                      surveyDetail?.survey_id
                                                    }
                                                    loginError={errors}
                                                    file={`false`}
                                                    textarea={`false`}
                                                    setIsShow={`false`}
                                                  />
                                                </div>
                                              )}
                                            </div>
                                          </>
                                        );
                                      })}
                                    </>
                                  )}
                                </>

                                <div className="template-button-block bg-white bottom-radius">
                                  <div>
                                    <button
                                      type="submit"
                                      className="btn btn-success me-4"
                                    >
                                      Save
                                    </button>
                                    <button
                                      className="btn cancel-btn"
                                      onClick={goBack}
                                    >
                                      cancel
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Form>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      )}
    </>
  );
};

export default PreviewSurveyAddEdit;