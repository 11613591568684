import axios from "axios";
import { headers } from "../page/Rounds/constants";
import Utils from "../components/Utils";

export const getAll = async (param, headers) => {
  let filter =
    "?" +
    (param
      ? Object.keys(param)
          .filter((key) => param[key] !== "")
          .map(function (key) {
            return (
              encodeURIComponent(key) + "=" + encodeURIComponent(param[key])
            );
          })
          .join("&")
      : "");
  return await axios
    .get("survey" + filter, headers)
    .then((res) => {
      return res?.data;
    })
    .catch((error) => Promise.reject(error?.response?.data));
};
export const getAllDashboard = async (param, headers) => {
  let filter =
    "?" +
    (param
      ? Object.keys(param)
          .filter((key) => param[key] !== "")
          .map(function (key) {
            return (
              encodeURIComponent(key) + "=" + encodeURIComponent(param[key])
            );
          })
          .join("&")
      : "");
  return await axios
    .get("survey/getDashboardSurveysCount/" + filter, headers)
    .then((res) => {
      return res?.data;
    })
    .catch((error) => Promise.reject(error?.response?.data));
};

export const getAllTodaysDeadlineSurveys = async (param, headers) => {
  let filter =
    "?" +
    (param
      ? Object.keys(param)
          .filter((key) => param[key] !== "")
          .map(function (key) {
            return (
              encodeURIComponent(key) + "=" + encodeURIComponent(param[key])
            );
          })
          .join("&")
      : "");
  return await axios
    .get("project/fetchAllTodayDeadlineSurveys" + filter, headers)
    .then((res) => {
      return res?.data;
    })
    .catch((error) => Promise.reject(error?.response?.data));
};
export const getOne = async (id, headers) => {
  return await axios
    .get("round/" + id, headers)
    .then((res) => {
      return res?.data;
    })
    .catch((error) => Promise.reject(error?.response?.data));
};

export const create = async (payload, headers) => {
  return await axios
    .post("/round/create", payload, headers)
    .then((res) => {
      return res?.data;
    })
    .catch((error) => Promise.reject(error?.response?.data));
};

export const CreateSurvey = async (payload, headers) => {
  return await axios
    .post("/round/createSurvey", payload, headers)
    .then((res) => {
      return res?.data;
    })
    .catch((error) => Promise.reject(error?.response?.data));
};
export const unAssignedSurvey = async (id) => {
  return await axios
    .get("round/unassign/" + id)
    .then((res) => {
      return res?.data;
    })
    .catch((error) => Promise.reject(error?.response?.data));
};
export const previewData = async (id, status) => {
  return await axios
    .get("survey/preview/" + id + "/" + status)
    .then((res) => {
      return res?.data;
    })
    .catch((error) => Promise.reject(error?.response?.data));
};
export const previewExport = async (id, status) => {
  return await axios
    .get("survey/previewExport/" + id + "/" + status)
    .then((res) => {
      return res?.data;
    })
    .catch((error) => Promise.reject(error?.response?.data));
};
export const notApproved = async (id) => {
  return await axios
    .get("survey/markAsNotApprove/" + id)
    .then((res) => {
      return res?.data;
    })
    .catch((error) => Promise.reject(error?.response?.data));
};
export const approved = async (id) => {
  return await axios
    .get("survey/markAsApprove/" + id)
    .then((res) => {
      return res?.data;
    })
    .catch((error) => Promise.reject(error?.response?.data));
};

export const editPreview = async (payload) => {
  return await axios
    .post("survey/saveSurvey", payload)
    .then((res) => {
      console.log("🚀 ~ .then ~ res:", res);
      return res?.data;
    })
    .catch((error) => {
      console.log("🚀 ~ editPreview ~ error:", error);

      Promise.reject(error?.response?.data);
    });
};

export const fetchRoundSurveysOffers = async (id) => {
  return await axios
    .get("round/roundSurveysOffers/" + id)
    .then((res) => {
      return res?.data;
    })
    .catch((error) => Promise.reject(error?.response?.data));
};
export const AssignMultipleOffer = async (data) => {
  return await axios
    .post("round/assignMultipleOffer", data)
    .then((res) => {
      return res?.data;
    })
    .catch((error) => Promise.reject(error?.response?.data));
};
export const UnAssignMultipleOffer = async (data) => {
  return await axios
    .post("round/unAssignMultipleOffer", data)
    .then((res) => {
      return res?.data;
    })
    .catch((error) => Promise.reject(error?.response?.data));
};
export const getAllFieldreps = async () => {
  return await axios
    .get("round/getAllFieldreps")
    .then((res) => {
      return res?.data;
    })
    .catch((error) => Promise.reject(error?.response?.data));
};
export const remove = async (id, header) => {
  return await axios
    .delete("round/" + id, header)
    .then((res) => {
      return res?.data;
    })
    .catch((error) => Promise.reject(error?.response?.data));
};
export const deleteMultiple = async (payload, header) => {
  return await axios
    .post("survey/multiDeleteSurveys", payload)
    .then((res) => {
      return res?.data;
    })
    .catch((error) => Promise.reject(error?.response?.data));
};
export const unAssginSurveyOffer = async (id) => {
  return await axios
    .delete("/round/unAssginedSurvey/" + id)
    .then((res) => {
      return res?.data;
    })
    .catch((error) => Promise.reject(error?.response?.data));
};
export const deleteSurvey = async (id) => {
  return await axios
    .delete("/round/deleteSurvey/" + id)
    .then((res) => {
      return res?.data;
    })
    .catch((error) => Promise.reject(error?.response?.data));
};
export const unAssginRoundSurvey = async (assignmentId, fieldrepId) => {
  return await axios
    .get("/round/unAssginedRoundSurvey/" + assignmentId + "/" + fieldrepId)
    .then((res) => {
      return res?.data;
    })
    .catch((error) => Promise.reject(error?.response?.data));
};
export const assginRoundSurvey = async (
  assignmentId,
  fieldrepId,
  clientCode
) => {
  return await axios
    .get(
      "/round/assginedRoundSurvey/" +
        assignmentId +
        "/" +
        fieldrepId +
        "/" +
        clientCode
    )
    .then((res) => {
      return res?.data;
    })
    .catch((error) => Promise.reject(error?.response?.data));
};
export const importSurveys = async (payload, headers) => {
  return await axios
    .post("/import/survey", payload, headers)
    .then((res) => {
      return res?.data;
    })
    .catch((error) => Promise.reject(error?.response?.data));
};
export const exportSurvey = async (payload, headers) => {
  return await axios
    .post("/export/survey", payload, headers)
    .then((res) => {
      return res?.data;
    })
    .catch((error) => Promise.reject(error?.response?.data));
};

export const getDemocsvSurveys = async () => {
  try {
    const response = await axios.post("import/download/surveys");
    Utils.downloadFile(response?.data.data.downloadFile);
  } catch (e) {
    console.log(e);
  }
};

export const getRefusedCount = async (payload, headers) => {
  return await axios
    .post("/survey/getRefusedCount", payload, headers)
    .then((res) => {
      return res?.data;
    })
    .catch((error) => Promise.reject(error?.response?.data));
};
export const getPendingCount = async (payload, headers) => {
  return await axios
    .post("/survey/getPendingCount", payload, headers)
    .then((res) => {
      return res?.data;
    })
    .catch((error) => Promise.reject(error?.response?.data));
};
export const getOfferedCount = async (payload, headers) => {
  return await axios
    .post("/survey/getOfferedCount", payload, headers)
    .then((res) => {
      return res?.data;
    })
    .catch((error) => Promise.reject(error?.response?.data));
};
export const getScheduledCount = async (payload, headers) => {
  return await axios
    .post("/survey/getScheduledCount", payload, headers)
    .then((res) => {
      return res?.data;
    })
    .catch((error) => Promise.reject(error?.response?.data));
};
export const getLateCount = async (payload, headers) => {
  return await axios
    .post("/survey/getLateCount", payload, headers)
    .then((res) => {
      return res?.data;
    })
    .catch((error) => Promise.reject(error?.response?.data));
};
export const getReportedCount = async (payload, headers) => {
  return await axios
    .post("/survey/getReportedCount", payload, headers)
    .then((res) => {
      return res?.data;
    })
    .catch((error) => Promise.reject(error?.response?.data));
};
export const getNotApprovedCount = async (payload, headers) => {
  return await axios
    .post("/survey/getNotApprovedCount", payload, headers)
    .then((res) => {
      return res?.data;
    })
    .catch((error) => Promise.reject(error?.response?.data));
};
export const getCompletedCount = async (payload, headers) => {
  return await axios
    .post("/survey/getCompletedCount", payload, headers)
    .then((res) => {
      return res?.data;
    })
    .catch((error) => Promise.reject(error?.response?.data));
};
export const getAllSurveyCounts = async (payload, headers) => {
  return await axios
    .post("/survey/getAllSurveyCounts", payload, headers)
    .then((res) => {
      return res?.data;
    })
    .catch((error) => Promise.reject(error?.response?.data));
};
export const surveysToday = async () => {
  return await axios
    .get("/project/fetchAllTodayDeadlineSurveys")
    .then((res) => {
      return res?.data;
    })
    .catch((error) => Promise.reject(error?.response?.data));
};

export const getTodayApprovedCount = async () => {
  return await axios
    .post("survey/getTodayApprovedCount")
    .then((res) => {
      return res?.data;
    })
    .catch((error) => Promise.reject(error?.response?.data));
};

export const markAsExport = async (id) => {
  return await axios
    .put("/survey/markAsExport/" + id)
    .then((res) => {
      return res?.data;
    })
    .catch((error) => Promise.reject(error?.response?.data));
};

export const removePreviewImage = async (id, name, clientCode) => {
  return await axios
    .get(`survey/singleImageRemove/${id}/${name}/${clientCode}`)
    .then((res) => {
      return res?.data;
    })
    .catch((error) => Promise.reject(error?.response?.data));
};

export const saveNote = async (id, payload) => {
  return await axios
    .put("/survey/saveNote/" + id, payload)
    .then((res) => {
      return res?.data;
    })
    .catch((error) => Promise.reject(error?.response?.data));
};

export const deleteNote = async (id) => {
  return await axios
    .put("/survey/deleteNote/" + id)
    .then((res) => {
      return res?.data;
    })
    .catch((error) => Promise.reject(error?.response?.data));
};
